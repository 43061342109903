import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/layout';

import RingingBells1 from '../../img/ringingbells1.jpg';
import RingingBells2 from '../../img/ringingbells2.jpg';
import RingingBells3 from '../../img/ringingbells3.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 100%;
  text-align: center;
  min-height: 700px;

  @media (min-width: 768px) {
    flex-wrap: wrap;
  }
`;

const RingingBellsContainer = styled.div`
  @media (min-width: 768px) {
    display: grid;
    justify-content: center;
    grid-template-areas: 'rb-top-left rb-top-right' 'rb-bottom rb-bottom';
    gap: 1rem;
  }
`;

const RingingBells = styled.img`
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const RingingBellsTop = styled(RingingBells)`
  grid-area: rb-bottom;
  justify-self: center;
  @media (min-width: 768px) {
    max-height: 450px;
    max-width: 600px;
  }
  margin-bottom: 2rem;
`;

const RingingBellsBottomLeft = styled(RingingBells)`
  grid-area: rb-top-left;
  @media (min-width: 768px) {
    max-height: 600px;
    max-width: 450px;
  }
`;

const RingingBellsBottomRight = styled(RingingBells)`
  grid-area: rb-top-right;
  @media (min-width: 768px) {
    max-height: 600px;
    max-width: 450px;
  }
`;

export default function RingingoftheBells() {
  return (
    <Layout>
      <Container>
        <h2>Community</h2>
        <p>
          <em>
            Throughout the year, members of Abraham H. Howland Jr. Lodge take
            time to provide care and resources to our community. When we come
            together for this common purpose--to help others--we form a special
            bond among ourselves and our community.
          </em>
        </p>
        <h3 id="ringing-bells">Ringing of the Bells</h3>
        <p>
          Members look forward to the friendly competition between lodges, but
          the true purpose is to assist the Salvation Army in their goal to
          bring help and happiness to those less fortunate. See below for
          pictures from this years event!
        </p>
        <RingingBellsContainer>
          <RingingBellsBottomLeft
            width="100%"
            alt="Ringing Bells 2"
            src={RingingBells2}
          />
          <RingingBellsBottomRight
            width="100%"
            alt="Ringing Bells 3"
            src={RingingBells3}
          />
          <RingingBellsTop
            width="100%"
            alt="Ringing Bells 1"
            src={RingingBells1}
          />
        </RingingBellsContainer>
      </Container>
    </Layout>
  );
}
